<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card title="Timeline Detail">
          <div class="w-100 text-center" v-if="isLoading">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-row v-if="!isLoading">
            <b-col cols="12" class="mb-4">
              <div class="d-flex justify-content-center">
                <img
                  :src="timelineDetail.thumbnail"
                  @error="
                    $event.target.src = require(`@/assets/images/avatars/no-image.png`)
                  "
                  :alt="timelineDetail.title"
                  style="object-fit: cover"
                />
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Title</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100">
                  {{ timelineDetail.title }}
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Registration Date</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100" v-if="timelineDetail.date_range">
                  {{ timelineDetail.date_range.registration_start_date }} to
                  {{ timelineDetail.date_range.registration_end_date }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Selection Date</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100" v-if="timelineDetail.date_range">
                  {{ timelineDetail.date_range.selection_start_date }} to
                  {{ timelineDetail.date_range.selection_end_date }}
                </p>
              </div>
              <div
                class="d-flex align-items-start align-items-md-center flex-column flex-md-row mb-2"
                style="gap: 24px"
              >
                <div
                  class="d-flex align-items-center justify-content-between w-50"
                >
                  <h5 class="mb-0">Annoucement Date</h5>
                  <h5 class="mb-0">:</h5>
                </div>
                <p class="mb-0 w-100" v-if="timelineDetail.date_range">
                  {{ timelineDetail.date_range.announcement_start_date }} to
                  {{ timelineDetail.date_range.announcement_end_date }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Requirement / Step">
          <div class="w-100 text-center" v-if="isLoading">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-row
            v-if="!isLoading"
            v-for="(item, index) in requirementData"
            class="mb-1"
            :key="index"
          >
            <b-col cols="12" class="mb-25 font-weight-bold">
              {{ item.name }}
            </b-col>
            <b-col cols="12" md="4">
              <div class="form-group" v-if="formPayload.requirements[index]">
                <label for="start-date">Start Date:</label>
                <flat-pickr
                  id="start-date"
                  class="form-control"
                  v-model="formPayload.requirements[index].start_date"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i:ss',
                    time_24hr: true,
                  }"
                />
                <small class="text-danger">start date for all participants to submit this process, leave blank if you don't want to set it</small>
              </div>
            </b-col>
            <b-col cols="12" md="4">
              <div class="form-group" v-if="formPayload.requirements[index]">
                <label for="end-date">End Date:</label>
                <flat-pickr
                  id="end-date"
                  class="form-control"
                  v-model="formPayload.requirements[index].end_date"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i:ss',
                    time_24hr: true,
                  }"
                />
                <small class="text-danger">end date for all participants to submit this process, leave blank if you don't want to set it</small>
              </div>
            </b-col>
            <b-col cols="12" md="4" v-if="item.code == 'ENGLISH_AND_GMAT_TEST'">
              <div class="form-group" v-if="formPayload.requirements[index]">
                <label for="url">Url:</label>
                <input
                  id="url"
                  type="text"
                  class="form-control"
                  v-model="formPayload.requirements[index].url"
                />
              </div>
            </b-col>
          </b-row>
          <b-button variant="primary" @click="attachRequirementToTimeline()">
            Save Requirement
          </b-button>
        </b-card>
        <b-card title="E-Learning">
          <div class="w-100 text-center" v-if="isLoading">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-row v-if="!isLoading">
            <b-col cols="12" lg="4">
              <div class="form-group">
                <label>Class: </label>
                <v-select
                  v-model="formPayload.classes"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  multiple
                  :options="elearningData"
                  :reduce="(type) => type.uuid"
                />
              </div>
            </b-col>
          </b-row>
          <b-button variant="primary" @click="attachElearningToTimeline()">
            Save E-Learning
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BButton,
  BRow,
  BSpinner,
  BCol,
  BFormFile,
  BFormCheckbox,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBModal,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    vSelect,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      currentPage: 1,
      requirementData: [],
      elearningData: [],
      timelineDetail: {},
      result: [],
      formPayload: {
        requirements: [],
        classes: [],
      },
      isLoading: false,
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.loadAllData();
    this.loadElearning();
  },
  watch: {
    elearningData(newValue) {
      if (newValue.length) {
        this.populateFormPayload();
      }
    },
  },
  methods: {
    loadAllData() {
      this.isLoading = true;
      const params = this.$route.params.uuid;
      Promise.all([
        this.$http.get("/api/v1/admin/ffl-timelines/" + params),
        this.$http.get("/api/v1/admin/ffl-requirements"),
      ])
        .then(([timelineResponse, requirementResponse]) => {
          this.timelineDetail = timelineResponse.data.data;
          this.requirementData = requirementResponse.data.data;
          this.populateFormPayload();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    loadElearning() {
      this.isLoading = true;
      this.$http
        .get("/api/v1/admin/e-learning", {
          params: {
            type: "female-future-leader",
          },
        })
        .then((response) => {
          this.elearningData = response.data.data.items;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    populateFormPayload() {
      if (this.timelineDetail.requirements && this.requirementData.length) {
        this.formPayload.requirements = this.requirementData.map((item) => {
          const matchedRequirement = this.timelineDetail.requirements.find(
            (req) => req.id === item.id
          );
          return {
            code: item.code,
            id: item.id,
            start_date: matchedRequirement ? matchedRequirement.start_date : "",
            end_date: matchedRequirement ? matchedRequirement.end_date : "",
            url:
              matchedRequirement && matchedRequirement.other
                ? matchedRequirement.other.url
                : "",
          };
        });
      }

      if (this.timelineDetail.classes && this.timelineDetail.classes.length) {
        this.formPayload.classes = this.timelineDetail.classes.map(
          (classItem) => classItem.uuid
        );
      }
    },
    attachRequirementToTimeline() {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to attach this requirement?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          const params = this.$route.params.uuid;

          const { classes, ...payloadWithoutClasses } = this.formPayload;

          this.$http
            .post(
              `/api/v1/admin/ffl-timelines/${params}/attach-requirement`,
              payloadWithoutClasses
            )
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "The requirement has been attached to timeline.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
              this.loadTimelineDetail();
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
    attachElearningToTimeline() {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to attach this e-learning?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          const params = this.$route.params.uuid;

          const formattedClasses = this.formPayload.classes.map(
            (classItem, index) => ({
              uuid: classItem,
              order: index + 1,
            })
          );

          const payload = {
            classes: formattedClasses,
            order: formattedClasses.map((_, idx) => idx + 1),
          };

          this.$http
            .post(
              `/api/v1/admin/ffl-timelines/${params}/attach-e-learning`,
              payload
            )
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "The e-learning has been attached to timeline.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
              this.loadTimelineDetail();
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
